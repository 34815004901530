.main-component {
  position: relative;
  padding: 0 1em;
  background-color: var(--main-bg-color);
}

.main-component .banner__home {
  height: 520px;
  width: calc(100% + 3em);
  margin-left: -2em;
  margin-top: -2em;
  margin-bottom: 2em;
  object-fit: cover;
  object-position: center;
}

.main-component .banner__home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 900px){
  .main-component .banner__home { 
    margin-top: 2em;
  }
}
@media (min-width: 900px) {
  .main-component {
    position: relative;
    padding: 2em 1em;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    /* align items in Main Axis */
    align-items: stretch;
    /* align items in Cross Axis */
    align-content: stretch;
    /* Extra space in Cross Axis */
  }
}

.main-component .main-title {
  margin-top: 0;
  margin-bottom: 1.85em;
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
  color: var(--default-color);
}

.main-component .panel {
  display: block;
  max-width: 50.7em;
  padding: .5em;
  border-radius: .5em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  /*background-color: var(--main-panel-color);*/
}

.main-component .upload-btn {
  display: block;
  border: none;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  cursor: pointer;
}

.main-component .upload {
  display: block;
}

.main-component .upload-txt {
  font-size: 1.6em;
  text-align: center;
  margin-bottom: 2em;
  color: var(--default-color);
}

t
.main-component .hidden {
  display: none;
}

.main-component .subtitle {
  margin: 0;
  font-size: 1.2em;
  text-align: center;
  color: var(--default-color);
}

.main-component .txt {
  display: block;
  margin: 0 auto .5em;
  font-size: 1em;
  text-align: center;
}

.main-component .txt.text-left {
  text-align: left;
}

.main-component .container {
  display: block;
  max-width: 46em;
  margin-left: auto;
  margin-right: auto;
}

.main-component .container label {
  display: block;
}

.main-component .container label span {
  display: block;
  font-size: 1.4em;
  margin-bottom: .5em;
}

.main-component .container .form-control {
  display: block;
  width: 100%;
  padding: 0.714em 1em;
  border: 0.07em solid var(--main-panel-color);
  margin-bottom: 1.143em;
  font-size: 1.4em;
  background-color: #fff;
  color: var(--default-color);
}

.main-component .container .form-control:disabled {
  background-color: var(--main-panel-color);
}

.main-component .container .btn-container {
  display: flex;
  margin-top: 4em;
}

.main-component .info {
  margin-top: 3.333em;
  font-size: 1.8em;
  text-align: center;
  color: var(--txt-color);
}

.main-component .info_file {
  margin-top: 2.5em;
  margin-bottom: 6.625em;
  font-size: 1.6em;
  text-align: center;
  color: var(--default-color);
}

@keyframes dots {
  0% {
    content: '';
  }
  15% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 4.4em 1em;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}

.loader h1 {
  position: relative;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loader h1::after {
  position: absolute;
  content: '';
  animation-name: dots;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
