@font-face {
  font-family: 'Avenir-Roman';
  src: url(/static/media/Avenir-Roman.aae719e7.eot?#iefix) format("embedded-opentype"), url(/static/media/Avenir-Roman.b3b06989.otf) format("opentype"), url(/static/media/Avenir-Roman.86c64411.woff) format("woff"), url(/static/media/Avenir-Roman.4ec7c6ed.ttf) format("truetype"), url(/static/media/Avenir-Roman.fd822fac.svg#Avenir-Roman) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url(/static/media/AvenirLTStd-Roman.fcccbbb3.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirLTStd-Roman.b1d7c6e0.otf) format("opentype"), url(/static/media/AvenirLTStd-Roman.cba6f170.woff) format("woff"), url(/static/media/AvenirLTStd-Roman.c2204e7a.ttf) format("truetype"), url(/static/media/AvenirLTStd-Roman.9a067bc2.svg#AvenirLTStd-Roman) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url(/static/media/AvenirLTStd-Book.689a956a.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirLTStd-Book.ecb0c2ae.otf) format("opentype"), url(/static/media/AvenirLTStd-Book.647e9ed0.woff) format("woff"), url(/static/media/AvenirLTStd-Book.f75fc464.ttf) format("truetype"), url(/static/media/AvenirLTStd-Book.521c9ef2.svg#AvenirLTStd-Book) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url(/static/media/AvenirLTStd-Black.bdf3030d.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirLTStd-Black.b1abb878.otf) format("opentype"), url(/static/media/AvenirLTStd-Black.99d4578a.woff) format("woff"), url(/static/media/AvenirLTStd-Black.319b0372.ttf) format("truetype"), url(/static/media/AvenirLTStd-Black.64437e6f.svg#AvenirLTStd-Black) format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-bg-color: #F8F8F8;
  --main-panel-color: #EBEBEB;
  --primary-color: #801854;
  --default-color: #474747;
  --secondary-color: #801854;
  --terciary-color: #F1B434;
  --txt-color: #95989A;
  --txt-color2: #282826;
  --gray-color: #343434;
  --bg-gray-color: #F2F2F2;
  --bg-black-color: #2C2A29;
  --borde-color: #C9CBCE;
  --boxed-width: 116.1em;
}

* {
  box-sizing: border-box;
  outline: none;
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
  --ReactInputVerificationCode-itemSpacing: 0.5rem;
}

body {
  margin: 0;
  font-size: 16px !important;
  color: #95989A !important;
  color: var(--txt-color) !important;
  font-family: 'Avenir-Roman';
}

div {
  font-family: 'Avenir-Roman';
}

._boxed {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  margin: 0 auto;
}

.block {
  margin: 1em 0;
}

.btn_actions {
  padding: 1em 0;
}

.btn {
  background-color: #801854 !important;
  background-color: var(--primary-color) !important;
  border-color: #801854 !important;
  border-color: var(--primary-color) !important;
  padding: 1.5em;
  color: #fff !important;
}

a,
a:visited,
a:active,
a:hover {
  color: #801854;
  color: var(--primary-color);
}

.subtitle2 {
  font-size: 22px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-family: 'AvenirLTStd-Book';
}

.subtitle3 {
  font-size: 20px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  font-family: 'AvenirLTStd-Book';
}

.button {
  font-family: 'AvenirLTStd-Black';
  background: #801854;
  background: var(--primary-color);
  border: none;
  border-radius: 2em;
  color: #fff !important;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: .5em 1.5em;
  text-align: center;
}

.button.full {
  width: 100%;
  display: block;
}

@media (min-width: 900px) {
  .button.large {
    padding: 1em 2em;
    font-size: 1em;
  }
}

/*
.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  opacity: 0;
  transition: all ease-in-out 200ms;
}
*/
.button:disabled::before {
  background-color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}

/*
.button:hover::before {
  opacity: 1;
}
*/
._btn_primary {
  background-color: #801854;
  background-color: var(--primary-color);
  color: #fff;
}

._btn_default {
  color: #fff;
  background-color: #ababab;
}

._btn_secondary {
  background-color: #801854;
  background-color: var(--primary-color);
  color: #fff;
}

._btn_secondary:hover {
  color: #801854 !important;
  color: var(--primary-color) !important;
  background-color: #fff;
  border: 1px solid #801854;
  border: 1px solid var(--primary-color);
}

._btn_secondary2 {
  background-color: #801854;
  background-color: var(--secondary-color);
  color: #fff;
}

._btn_terciary {
  background-color: #2C2A29;
  background-color: var(--bg-black-color);
  color: #801854;
  color: var(--primary-color);
  border: 1px solid #801854;
  border: 1px solid var(--primary-color);
}

h2 {
  font-size: 28px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.logo canvas {
  margin: auto;
  display: block;
}

.margin-bottom-mobile {
  margin-bottom: 2em;
}

.docInfo {
  background: #fff;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.datoFile p {
  margin: 1em 0 0 0;
}

.datoFile p:first-child {
  margin: 0;
}

.datoFile a, .datoFile a:hover {
  color: #801854;
  color: var(--primary-color)
}

.datoFile,
.docInfo {
  word-break: break-all;
}

@media (min-width: 900px) {
  .datoFile,
  .docInfo {
    /*word-break: inherit;*/
    word-break: break-all;
  }
  .docInfo {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  ._boxed {
    display: block;
    width: 96vw;
    max-width: 116.1em;
    max-width: var(--boxed-width);
    margin-left: auto;
    margin-right: auto;
  }
  .content {
    width: 55em;
    word-break: normal;
  }
  .content2 {
    width: 100%;
  }
  .margin-bottom-mobile {
    margin-bottom: 0;
  }
  .btn_actions {
    display: flex;
    justify-content: space-around;
  }
}

.form {
  padding: 1em 1.5em;
  flex: 1 1;
}

.form-row {
  margin-bottom: .5em;
}

.form-label {
  color: #95989A;
  color: var(--txt-color);
  display: block;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: .5em;
}

.form-input {
  background-color: #F2F2F2;
  background-color: var(--bg-gray-color);
  border: 1px solid #C9CBCE;
  border: 1px solid var(--borde-color);
  color: #343434;
  color: var(--gray-color);
  display: block;
  font-size: 1em;
  margin-bottom: .5em;
  padding: .5em .8em;
  border-radius: .5em;
  width: 100%;
}

.form-input:focus {
  outline: 0;
}

@media (min-width: 900px) {
  .form-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .form-label {
    width: 38%;
    text-align: right;
    padding-right: 1em;
  }
  .form-input {
    width: 62%;
  }
}

.form-slider > div > div > div:nth-child(3) {
  width: 70px !important;
  height: 18px !important;
  line-height: 18px !important;
  border-radius: 26px !important;
}

.form-slider > div > div > div:nth-child(3)::before {
  content: "DRAG ME";
  color: #fff;
  font-size: 0.7em;
  text-align: center;
  line-height: 18px;
  display: block;
}

.form-slider > div {
  width: 73% !important;
  margin: 24px auto 24px !important;
}

.form-button {
  background: #801854;
  background: var(--primary-color);
  border: 1px solid #801854;
  border: 1px solid var(--primary-color);
  border-radius: 1em;
  color: #fff;
  cursor: pointer;
  font-size: .875em;
  padding: .5em 3.5em;
  cursor: pointer;
}

.form-button:disabled {
  background: #95989A;
  background: var(--txt-color);
  border: 1px solid #95989A;
  border: 1px solid var(--txt-color);
  cursor: default;
}

.form-button:not(:disabled):hover {
  background: #801854;
  background: var(--primary-color);
}

.form-helper {
  color: #474747;
  color: var(--default-color);
  font-size: .75em;
  padding: .3em;
}

.form-helper a {
  color: #801854 !important;
  color: var(--primary-color) !important;
  cursor: pointer;
}

.form-helper a.style1 {
  color: #801854 !important;
  color: var(--primary-color) !important;
}

.form-helper a:hover {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.align-center {
  display: flex;
  justify-content: center !important;
}

.align-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-right {
  display: flex;
  justify-content: flex-end !important;
}

.flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.align-center {
  display: flex;
  justify-content: center !important;
}

.align-between {
  display: flex;
  justify-content: space-between;
}

.message {
  font-size: 0.875em;
  padding: 0.5em 0 0;
  text-align: center;
}

.message-error {
  color: #c33030;
}

.message-success {
  color: #00b8a0;
}

.text-left {
  text-align: left;
}

.centerWrap {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.navi {
  position: absolute;
  left: 0;
  top: -85px;
}

@media (min-width: 480px) {
  .navi {
    top: -44px;
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .navi {
    top: -44px;
    width: 73%;
  }
}

.navi__text {
  float: left;
  line-height: 36px;
  padding: 0 1em 0 0;
}

@media (min-width: 1024px) {
  .navi__text {
    padding: 0;
    line-height: 30px;
  }
}

.navi__text #seleccionedesktop {
  display: none;
}

@media (min-width: 1024px) {
  .navi__text #seleccionedesktop {
    display: inline;
  }
}

.navi__action {
  float: left;
}

@media (min-width: 1024px) {
  .navi__action {
    float: right;
  }
}

.navi__action__left {
  position: relative;
  margin-right: 5px;
}

.navi__action__left span {
  position: absolute;
  left: -11px;
  top: -11px;
  border-radius: 20px;
  border: 3px solid #801854;
  border: 3px solid var(--primary-color);
  background: #000;
  display: block;
  z-index: 2;
  color: #fff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
}

.navi__action__left button {
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
}

.contenedor {
  position: relative;
  margin: 4em 0 0 0;
}

@media (min-width: 480px) {
  .contenedor {
    margin: 2em 0 0 0;
  }
}

@media (min-width: 768px) {
  .contenedor {
    margin: 1em 0 0 0;
  }
}

.contenedor > span {
  min-height: 150px;
  display: block;
}

.contenedor .ant-upload-list.ant-upload-list-text {
  display: none;
}

.navi2 {
  position: absolute;
  top: 0;
  left: 50%;
  right: inherit;
  transform: translateX(-50%);
  width: 100%;
}

@media (min-width: 480px) {
  .navi2 {
    width: inherit;
    left: inherit;
    right: 0;
    transform: none;
  }
}

.navi2__action {
  display: flex;
  justify-content: space-between;
}

.navi2__action__left {
  position: relative;
  margin-right: 5px;
}

.navi2__action__left span {
  position: absolute;
  left: -11px;
  top: -11px;
  border-radius: 20px;
  border: 3px solid #801854;
  border: 3px solid var(--primary-color);
  background: #000;
  display: block;
  z-index: 2;
  color: #fff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
}

.navi2__action__left button {
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
}

.detalleDocumento .logo {
  display: flex;
  align-content: center;
  justify-content: center;
}

.detalleDocumento p strong {
  font-weight: 600;
}

.detalleDocumento p.warning {
  font-weight: 600;
  color: #F1B434;
  color: var(--terciary-color);
}

.detalleDocumento p a {
  text-decoration: none;
}

.detalleDocumento p a:hover {
  text-decoration: underline;
}

.itemResul {
  border-top: 1px solid #333;
  padding: 1.5em 0;
}

.itemResul:first-child {
  border-top: 0;
}

.itemResul .logo {
  display: flex;
  align-content: center;
  justify-content: center;
}

.itemResul p strong {
  font-weight: 600;
}

.itemResul p.warning {
  font-weight: 600;
  color: #F1B434;
  color: var(--terciary-color);
}

.itemResul p a {
  text-decoration: none;
}

.itemResul p a:hover {
  text-decoration: underline;
}

.dragu.ant-upload.ant-upload-drag {
  border: 0 !important;
}

.dragu .ant-upload-drag-container {
  background: #ebe7e7;
  margin: 10px 0 0;
  top: 18px;
  position: relative;
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px dashed #474747;
  padding: 1em;
  min-height: 150px;
  cursor: default;
}

.filesBatch {
  position: absolute;
  width: 100%;
  top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px dashed #474747;
  padding: 1em;
  min-height: 150px;
}

.filesBatch__item {
  background: #fff;
  border: 1px solid #474747;
  padding: 1em;
  position: relative;
  margin: .5em;
  width: 98%;
}

.filesBatch__item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}

.filesBatch__item__type {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin: 0 0 5px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 700;
  font-size: 30px;
}

.filesBatch__item__type.JPG, .filesBatch__item__type.PNG {
  background-color: #DAE4F5;
}

.filesBatch__item__type.W {
  background-color: #DAE4F5;
}

.filesBatch__item__type.X {
  background-color: #DCF1E2;
}

.filesBatch__item__type.PPT {
  background-color: #FFE2D4;
}

.filesBatch__item__type.PDF {
  background-color: #F9DDDD;
}

.filesBatch__item__type.generico {
  background-color: #f1f1f1;
}

@media (min-width: 480px) {
  .filesBatch__item {
    width: 46%;
  }
}

@media (min-width: 768px) {
  .filesBatch__item {
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .filesBatch__item {
    width: 22%;
  }
}

.filesBatch__item__close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 22px;
  z-index: 999;
}

.filesBatch__item__close__ico {
  width: 24px;
  height: 24px;
  left: 0;
  line-height: 24px;
  text-align: center;
  position: relative;
  background: #801854;
  background: var(--primary-color);
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  z-index: 99;
  display: block;
}

.msgbatch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 .5em 1.2em;
}

.App {

}

@media (min-width: 900px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 900px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: 100%;
  }
}
.header-component{margin-bottom:1em} .header-component .logo_enterprise { margin-left: -40px !important; } @media (max-width: 900px){ .header .logo_enterprise { margin-left: auto !important; } }@media (min-width: 900px){.header-component{margin-bottom:0}}.header-component .logo{display:table;max-height:4em;margin-bottom:1em;margin-left:auto;margin-right:auto}@media (min-width: 900px){.header-component .logo{display:inline-block;margin-bottom:0;margin-left:inherit;margin-right:inherit}}.header-component .menu{display:flex;justify-content:center}@media (min-width: 900px){.header-component .header{display:flex;align-items:center;justify-content:space-between;padding:1em 0}}.header-component .header .menu a{margin:0 1em}

.home {
    
}

.change-color-img {
    -webkit-filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}

.linear-between {
    height: 2px;
    background: #34302f;
    width: 100%;
    margin: 6em 0;
}

.List_beneficios {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.beneficio {
    padding: 1.5em;
    width: 165px;
    height: 17em;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;
}



.login-form {
  font-size: 1em;
}

.login-form-row {
  margin-bottom: 1em;
}

.login-form-row.margin-left {
  margin-left: 2em;
}

.login-form-actions {
  padding: 0;
}

.login-form-label {
  color: #343434;
  display: block;
  font-size: .75em;
  margin-bottom: .5em;
}

.login-form-input {
  background-color: #d1d1d1;
  border: none;
  color: #343434;
  display: block;
  font-size: .875em;
  margin-bottom: .5em;
  padding: .5em;
  width: 100%;
}

.login-form-input.token {
  border: 0;
  background: #585858;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.login-form-input:focus {
  background: #c7c7c7;
  outline: 0;
}

.login-form-qr {
  padding: 1em;
  margin-right: 1em;
  background: #fff;
}

.login-form-qr-token {
  width: 100%;
}

.login-form-qr-token p {
  color: #fff;
}

.loginWrap {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1;
}

.login {
  background: var(--bg-black-color);
  color: #fff;
  border-radius: .5em;
  padding: 2em 4em;
}

.login .title {
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 1em;
  font-family: 'AvenirLTStd-Black';
}

.login .text {
  font-size: 1em;
  color: #fff !important;
}

.login .text-alert {
  color: #c33030;
  font-weight: bold;
}

.login .text a {
  color: var(--terciary-color);
}

.login-top {
  margin-bottom: 1em;
}

.login-sub-top {
  display: flex;
  margin-bottom: 1em;
}

.login-sub-top .num {
  position: relative;
  border-radius: 20px;
  background: #c9c9c9;
  line-height: 25px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #343434;
  margin-right: 5px;
}

.login-sub-top > .subtitle {
  color: #fff !important;
  font-family: 'AvenirLTStd-Book';
}

.login .down-actions {
  display: flex;
}

.login .down-actions .btn-down {
  margin: 0 0 0 1em;
}

.login .down-actions .btn-down:first-child {
  margin: 0;
}

.login .ant-form-item-label label {
  color: #fff !important;
}

.login .ant-form-item-label {
  text-align: left;
  line-height: 19.99px;
}

.Message__Custom {
  color: red;
  height: 20px;
  margin-bottom: 5px;
  visibility: hidden;
}

.isError__Inputcustom {
  border-color: red;
  transition: 0.5s;
}
.isError__Inputcustom:focus {
  border-color: red;
}

.isError__Messagecustom {
  color: red;
  height: 20px;
  margin-bottom: 5px;
  transition: 1s;
}

.ReactInputVerificationCode__item {
  color: white;
}
.register {

}

.register .title {
  color: var(--txt-color2);
  font-size: 1em;
  font-weight: normal;
  margin: 0 0 1m;
}

.register .text {
  color: var(--default-color);
  font-size: .875em;
  margin: 0 0 .2em;
}

.text-alert {
  color: #c33030;
  font-weight: bold;
}





.margin-bottom {
  margin-bottom: 4em;
}



.desc {
  margin-bottom: 4em;
}

.justEnd {
  display: flex;
  justify-content: flex-end;
}

.listHeader {
  position: relative;
  margin-bottom: 2em;
}

.listHeader h2 {
  margin: 0;
}

.listHeader a {
  position: relative;
  margin: 15px auto 0;
  display: table;
}

@media (min-width: 900px) {
  .listHeader a {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.listDocs {
  padding: 0 2em;
}

.listItem {
  border-bottom: 1px solid #CCCDCE;
  padding: .8em 0;
}

.margin-bottom {
  margin-bottom: 4em;
}



.rpta { text-align: center }


.main-component {
  position: relative;
  padding: 0 1em;
  background-color: var(--main-bg-color);
}

.main-component .banner__home {
  height: 520px;
  width: calc(100% + 3em);
  margin-left: -2em;
  margin-top: -2em;
  margin-bottom: 2em;
  object-fit: cover;
  object-position: center;
}

.main-component .banner__home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 900px){
  .main-component .banner__home { 
    margin-top: 2em;
  }
}
@media (min-width: 900px) {
  .main-component {
    position: relative;
    padding: 2em 1em;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: flex-start;
    /* align items in Main Axis */
    align-items: stretch;
    /* align items in Cross Axis */
    align-content: stretch;
    /* Extra space in Cross Axis */
  }
}

.main-component .main-title {
  margin-top: 0;
  margin-bottom: 1.85em;
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
  color: var(--default-color);
}

.main-component .panel {
  display: block;
  max-width: 50.7em;
  padding: .5em;
  border-radius: .5em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  /*background-color: var(--main-panel-color);*/
}

.main-component .upload-btn {
  display: block;
  border: none;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  cursor: pointer;
}

.main-component .upload {
  display: block;
}

.main-component .upload-txt {
  font-size: 1.6em;
  text-align: center;
  margin-bottom: 2em;
  color: var(--default-color);
}

t
.main-component .hidden {
  display: none;
}

.main-component .subtitle {
  margin: 0;
  font-size: 1.2em;
  text-align: center;
  color: var(--default-color);
}

.main-component .txt {
  display: block;
  margin: 0 auto .5em;
  font-size: 1em;
  text-align: center;
}

.main-component .txt.text-left {
  text-align: left;
}

.main-component .container {
  display: block;
  max-width: 46em;
  margin-left: auto;
  margin-right: auto;
}

.main-component .container label {
  display: block;
}

.main-component .container label span {
  display: block;
  font-size: 1.4em;
  margin-bottom: .5em;
}

.main-component .container .form-control {
  display: block;
  width: 100%;
  padding: 0.714em 1em;
  border: 0.07em solid var(--main-panel-color);
  margin-bottom: 1.143em;
  font-size: 1.4em;
  background-color: #fff;
  color: var(--default-color);
}

.main-component .container .form-control:disabled {
  background-color: var(--main-panel-color);
}

.main-component .container .btn-container {
  display: flex;
  margin-top: 4em;
}

.main-component .info {
  margin-top: 3.333em;
  font-size: 1.8em;
  text-align: center;
  color: var(--txt-color);
}

.main-component .info_file {
  margin-top: 2.5em;
  margin-bottom: 6.625em;
  font-size: 1.6em;
  text-align: center;
  color: var(--default-color);
}

@-webkit-keyframes dots {
  0% {
    content: '';
  }
  15% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

@keyframes dots {
  0% {
    content: '';
  }
  15% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 4.4em 1em;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}

.loader h1 {
  position: relative;
  -webkit-animation-name: opacity;
          animation-name: opacity;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.loader h1::after {
  position: absolute;
  content: '';
  -webkit-animation-name: dots;
          animation-name: dots;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.footer-component {

}
.wrapper {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  padding: 0.5em 0 0.5em 0;
  font-size: 0.875em;
  color: var(--txt-color);
}
.logoFooter {
  display: inline-block;
}

.wrapper div {
  width: 8em;
}

.wrapper img {
  width: 100%;
}

/*p {
  padding-top:'1em';
  padding-right:'3em';

}*/
