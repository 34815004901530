.register {

}

.register .title {
  color: var(--txt-color2);
  font-size: 1em;
  font-weight: normal;
  margin: 0 0 1m;
}

.register .text {
  color: var(--default-color);
  font-size: .875em;
  margin: 0 0 .2em;
}

.text-alert {
  color: #c33030;
  font-weight: bold;
}


