.login-form {
  font-size: 1em;
}

.login-form-row {
  margin-bottom: 1em;
}

.login-form-row.margin-left {
  margin-left: 2em;
}

.login-form-actions {
  padding: 0;
}

.login-form-label {
  color: #343434;
  display: block;
  font-size: .75em;
  margin-bottom: .5em;
}

.login-form-input {
  background-color: #d1d1d1;
  border: none;
  color: #343434;
  display: block;
  font-size: .875em;
  margin-bottom: .5em;
  padding: .5em;
  width: 100%;
}

.login-form-input.token {
  border: 0;
  background: #585858;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.login-form-input:focus {
  background: #c7c7c7;
  outline: 0;
}

.login-form-qr {
  padding: 1em;
  margin-right: 1em;
  background: #fff;
}

.login-form-qr-token {
  width: 100%;
}

.login-form-qr-token p {
  color: #fff;
}

.loginWrap {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}

.login {
  background: var(--bg-black-color);
  color: #fff;
  border-radius: .5em;
  padding: 2em 4em;
}

.login .title {
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 1em;
  font-family: 'AvenirLTStd-Black';
}

.login .text {
  font-size: 1em;
  color: #fff !important;
}

.login .text-alert {
  color: #c33030;
  font-weight: bold;
}

.login .text a {
  color: var(--terciary-color);
}

.login-top {
  margin-bottom: 1em;
}

.login-sub-top {
  display: flex;
  margin-bottom: 1em;
}

.login-sub-top .num {
  position: relative;
  border-radius: 20px;
  background: #c9c9c9;
  line-height: 25px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #343434;
  margin-right: 5px;
}

.login-sub-top > .subtitle {
  color: #fff !important;
  font-family: 'AvenirLTStd-Book';
}

.login .down-actions {
  display: flex;
}

.login .down-actions .btn-down {
  margin: 0 0 0 1em;
}

.login .down-actions .btn-down:first-child {
  margin: 0;
}

.login .ant-form-item-label label {
  color: #fff !important;
}

.login .ant-form-item-label {
  text-align: left;
  line-height: 19.99px;
}

.Message__Custom {
  color: red;
  height: 20px;
  margin-bottom: 5px;
  visibility: hidden;
}

.isError__Inputcustom {
  border-color: red;
  transition: 0.5s;
}
.isError__Inputcustom:focus {
  border-color: red;
}

.isError__Messagecustom {
  color: red;
  height: 20px;
  margin-bottom: 5px;
  transition: 1s;
}

.ReactInputVerificationCode__item {
  color: white;
}