.App {

}

@media (min-width: 900px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 900px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: 100%;
  }
}