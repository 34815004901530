.footer-component {

}
.wrapper {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  padding: 0.5em 0 0.5em 0;
  font-size: 0.875em;
  color: var(--txt-color);
}
.logoFooter {
  display: inline-block;
}

.wrapper div {
  width: 8em;
}

.wrapper img {
  width: 100%;
}

/*p {
  padding-top:'1em';
  padding-right:'3em';

}*/