.desc {
  margin-bottom: 4em;
}

.justEnd {
  display: flex;
  justify-content: flex-end;
}

.listHeader {
  position: relative;
  margin-bottom: 2em;
}

.listHeader h2 {
  margin: 0;
}

.listHeader a {
  position: relative;
  margin: 15px auto 0;
  display: table;
}

@media (min-width: 900px) {
  .listHeader a {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.listDocs {
  padding: 0 2em;
}

.listItem {
  border-bottom: 1px solid #CCCDCE;
  padding: .8em 0;
}
