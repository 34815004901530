@font-face {
  font-family: 'Avenir-Roman';
  src: url("../src/assets/fonts/Avenir-Roman.eot?#iefix") format("embedded-opentype"), url("../src/assets/fonts/Avenir-Roman.otf") format("opentype"), url("../src/assets/fonts/Avenir-Roman.woff") format("woff"), url("../src/assets/fonts/Avenir-Roman.ttf") format("truetype"), url("../src/assets/fonts/Avenir-Roman.svg#Avenir-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url("../src/assets/fonts/AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("../src/assets/fonts/AvenirLTStd-Roman.otf") format("opentype"), url("../src/assets/fonts/AvenirLTStd-Roman.woff") format("woff"), url("../src/assets/fonts/AvenirLTStd-Roman.ttf") format("truetype"), url("../src/assets/fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url("../src/assets/fonts/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("../src/assets/fonts/AvenirLTStd-Book.otf") format("opentype"), url("../src/assets/fonts/AvenirLTStd-Book.woff") format("woff"), url("../src/assets/fonts/AvenirLTStd-Book.ttf") format("truetype"), url("../src/assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url("../src/assets/fonts/AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"), url("../src/assets/fonts/AvenirLTStd-Black.otf") format("opentype"), url("../src/assets/fonts/AvenirLTStd-Black.woff") format("woff"), url("../src/assets/fonts/AvenirLTStd-Black.ttf") format("truetype"), url("../src/assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-bg-color: #F8F8F8;
  --main-panel-color: #EBEBEB;
  --primary-color: #801854;
  --default-color: #474747;
  --secondary-color: #801854;
  --terciary-color: #F1B434;
  --txt-color: #95989A;
  --txt-color2: #282826;
  --gray-color: #343434;
  --bg-gray-color: #F2F2F2;
  --bg-black-color: #2C2A29;
  --borde-color: #C9CBCE;
  --boxed-width: 116.1em;
}

* {
  box-sizing: border-box;
  outline: none;
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
  --ReactInputVerificationCode-itemSpacing: 0.5rem;
}

body {
  margin: 0;
  font-size: 16px !important;
  color: var(--txt-color) !important;
  font-family: 'Avenir-Roman';
}

div {
  font-family: 'Avenir-Roman';
}

._boxed {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  margin: 0 auto;
}

.block {
  margin: 1em 0;
}

.btn_actions {
  padding: 1em 0;
}

.btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  padding: 1.5em;
  color: #fff !important;
}

a,
a:visited,
a:active,
a:hover {
  color: var(--primary-color);
}

.subtitle2 {
  font-size: 22px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-family: 'AvenirLTStd-Book';
}

.subtitle3 {
  font-size: 20px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  font-family: 'AvenirLTStd-Book';
}

.button {
  font-family: 'AvenirLTStd-Black';
  background: var(--primary-color);
  border: none;
  border-radius: 2em;
  color: #fff !important;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: .5em 1.5em;
  text-align: center;
}

.button.full {
  width: 100%;
  display: block;
}

@media (min-width: 900px) {
  .button.large {
    padding: 1em 2em;
    font-size: 1em;
  }
}

/*
.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  opacity: 0;
  transition: all ease-in-out 200ms;
}
*/
.button:disabled::before {
  background-color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}

/*
.button:hover::before {
  opacity: 1;
}
*/
._btn_primary {
  background-color: var(--primary-color);
  color: #fff;
}

._btn_default {
  color: #fff;
  background-color: #ababab;
}

._btn_secondary {
  background-color: var(--primary-color);
  color: #fff;
}

._btn_secondary:hover {
  color: var(--primary-color) !important;
  background-color: #fff;
  border: 1px solid var(--primary-color);
}

._btn_secondary2 {
  background-color: var(--secondary-color);
  color: #fff;
}

._btn_terciary {
  background-color: var(--bg-black-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

h2 {
  font-size: 28px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.logo canvas {
  margin: auto;
  display: block;
}

.margin-bottom-mobile {
  margin-bottom: 2em;
}

.docInfo {
  background: #fff;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.datoFile p {
  margin: 1em 0 0 0;
}

.datoFile p:first-child {
  margin: 0;
}

.datoFile a, .datoFile a:hover {
  color: var(--primary-color)
}

.datoFile,
.docInfo {
  word-break: break-all;
}

@media (min-width: 900px) {
  .datoFile,
  .docInfo {
    /*word-break: inherit;*/
    word-break: break-all;
  }
  .docInfo {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  ._boxed {
    display: block;
    width: 96vw;
    max-width: var(--boxed-width);
    margin-left: auto;
    margin-right: auto;
  }
  .content {
    width: 55em;
    word-break: normal;
  }
  .content2 {
    width: 100%;
  }
  .margin-bottom-mobile {
    margin-bottom: 0;
  }
  .btn_actions {
    display: flex;
    justify-content: space-around;
  }
}

.form {
  padding: 1em 1.5em;
  flex: 1;
}

.form-row {
  margin-bottom: .5em;
}

.form-label {
  color: var(--txt-color);
  display: block;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: .5em;
}

.form-input {
  background-color: var(--bg-gray-color);
  border: 1px solid var(--borde-color);
  color: var(--gray-color);
  display: block;
  font-size: 1em;
  margin-bottom: .5em;
  padding: .5em .8em;
  border-radius: .5em;
  width: 100%;
}

.form-input:focus {
  outline: 0;
}

@media (min-width: 900px) {
  .form-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .form-label {
    width: 38%;
    text-align: right;
    padding-right: 1em;
  }
  .form-input {
    width: 62%;
  }
}

.form-slider > div > div > div:nth-child(3) {
  width: 70px !important;
  height: 18px !important;
  line-height: 18px !important;
  border-radius: 26px !important;
}

.form-slider > div > div > div:nth-child(3)::before {
  content: "DRAG ME";
  color: #fff;
  font-size: 0.7em;
  text-align: center;
  line-height: 18px;
  display: block;
}

.form-slider > div {
  width: 73% !important;
  margin: 24px auto 24px !important;
}

.form-button {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 1em;
  color: #fff;
  cursor: pointer;
  font-size: .875em;
  padding: .5em 3.5em;
  cursor: pointer;
}

.form-button:disabled {
  background: var(--txt-color);
  border: 1px solid var(--txt-color);
  cursor: default;
}

.form-button:not(:disabled):hover {
  background: var(--primary-color);
}

.form-helper {
  color: var(--default-color);
  font-size: .75em;
  padding: .3em;
}

.form-helper a {
  color: var(--primary-color) !important;
  cursor: pointer;
}

.form-helper a.style1 {
  color: var(--primary-color) !important;
}

.form-helper a:hover {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.align-center {
  display: flex;
  justify-content: center !important;
}

.align-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-right {
  display: flex;
  justify-content: flex-end !important;
}

.flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.align-center {
  display: flex;
  justify-content: center !important;
}

.align-between {
  display: flex;
  justify-content: space-between;
}

.message {
  font-size: 0.875em;
  padding: 0.5em 0 0;
  text-align: center;
}

.message-error {
  color: #c33030;
}

.message-success {
  color: #00b8a0;
}

.text-left {
  text-align: left;
}

.centerWrap {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.navi {
  position: absolute;
  left: 0;
  top: -85px;
}

@media (min-width: 480px) {
  .navi {
    top: -44px;
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .navi {
    top: -44px;
    width: 73%;
  }
}

.navi__text {
  float: left;
  line-height: 36px;
  padding: 0 1em 0 0;
}

@media (min-width: 1024px) {
  .navi__text {
    padding: 0;
    line-height: 30px;
  }
}

.navi__text #seleccionedesktop {
  display: none;
}

@media (min-width: 1024px) {
  .navi__text #seleccionedesktop {
    display: inline;
  }
}

.navi__action {
  float: left;
}

@media (min-width: 1024px) {
  .navi__action {
    float: right;
  }
}

.navi__action__left {
  position: relative;
  margin-right: 5px;
}

.navi__action__left span {
  position: absolute;
  left: -11px;
  top: -11px;
  border-radius: 20px;
  border: 3px solid var(--primary-color);
  background: #000;
  display: block;
  z-index: 2;
  color: #fff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
}

.navi__action__left button {
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
}

.contenedor {
  position: relative;
  margin: 4em 0 0 0;
}

@media (min-width: 480px) {
  .contenedor {
    margin: 2em 0 0 0;
  }
}

@media (min-width: 768px) {
  .contenedor {
    margin: 1em 0 0 0;
  }
}

.contenedor > span {
  min-height: 150px;
  display: block;
}

.contenedor .ant-upload-list.ant-upload-list-text {
  display: none;
}

.navi2 {
  position: absolute;
  top: 0;
  left: 50%;
  right: inherit;
  transform: translateX(-50%);
  width: 100%;
}

@media (min-width: 480px) {
  .navi2 {
    width: inherit;
    left: inherit;
    right: 0;
    transform: none;
  }
}

.navi2__action {
  display: flex;
  justify-content: space-between;
}

.navi2__action__left {
  position: relative;
  margin-right: 5px;
}

.navi2__action__left span {
  position: absolute;
  left: -11px;
  top: -11px;
  border-radius: 20px;
  border: 3px solid var(--primary-color);
  background: #000;
  display: block;
  z-index: 2;
  color: #fff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
}

.navi2__action__left button {
  padding: 2px 10px;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
}

.detalleDocumento .logo {
  display: flex;
  align-content: center;
  justify-content: center;
}

.detalleDocumento p strong {
  font-weight: 600;
}

.detalleDocumento p.warning {
  font-weight: 600;
  color: var(--terciary-color);
}

.detalleDocumento p a {
  text-decoration: none;
}

.detalleDocumento p a:hover {
  text-decoration: underline;
}

.itemResul {
  border-top: 1px solid #333;
  padding: 1.5em 0;
}

.itemResul:first-child {
  border-top: 0;
}

.itemResul .logo {
  display: flex;
  align-content: center;
  justify-content: center;
}

.itemResul p strong {
  font-weight: 600;
}

.itemResul p.warning {
  font-weight: 600;
  color: var(--terciary-color);
}

.itemResul p a {
  text-decoration: none;
}

.itemResul p a:hover {
  text-decoration: underline;
}

.dragu.ant-upload.ant-upload-drag {
  border: 0 !important;
}

.dragu .ant-upload-drag-container {
  background: #ebe7e7;
  margin: 10px 0 0;
  top: 18px;
  position: relative;
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px dashed #474747;
  padding: 1em;
  min-height: 150px;
  cursor: default;
}

.filesBatch {
  position: absolute;
  width: 100%;
  top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px dashed #474747;
  padding: 1em;
  min-height: 150px;
}

.filesBatch__item {
  background: #fff;
  border: 1px solid #474747;
  padding: 1em;
  position: relative;
  margin: .5em;
  width: 98%;
}

.filesBatch__item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}

.filesBatch__item__type {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin: 0 0 5px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 700;
  font-size: 30px;
}

.filesBatch__item__type.JPG, .filesBatch__item__type.PNG {
  background-color: #DAE4F5;
}

.filesBatch__item__type.W {
  background-color: #DAE4F5;
}

.filesBatch__item__type.X {
  background-color: #DCF1E2;
}

.filesBatch__item__type.PPT {
  background-color: #FFE2D4;
}

.filesBatch__item__type.PDF {
  background-color: #F9DDDD;
}

.filesBatch__item__type.generico {
  background-color: #f1f1f1;
}

@media (min-width: 480px) {
  .filesBatch__item {
    width: 46%;
  }
}

@media (min-width: 768px) {
  .filesBatch__item {
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .filesBatch__item {
    width: 22%;
  }
}

.filesBatch__item__close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 22px;
  z-index: 999;
}

.filesBatch__item__close__ico {
  width: 24px;
  height: 24px;
  left: 0;
  line-height: 24px;
  text-align: center;
  position: relative;
  background: var(--primary-color);
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  z-index: 99;
  display: block;
}

.msgbatch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 .5em 1.2em;
}
