
.home {
    
}

.change-color-img {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}

.linear-between {
    height: 2px;
    background: #34302f;
    width: 100%;
    margin: 6em 0;
}

.List_beneficios {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.beneficio {
    padding: 1.5em;
    width: 165px;
    height: 17em;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;
}


